import {
  Box,
  TextField,
  Avatar,
  Typography,
  Container,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoggedIn, setAdmin } from "../../redux/auth";
import authService from "../../services/auth.service";
import { removeAllSpace } from "../../helper";
import { toast } from "react-toastify";
import { COMMON_ERROR_MSG } from "../../configs/constant";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import envConstants from "../../configs/key";
import assets from "../../assets";
// import Logo from '../../../public/favicon.ico';

const defaultTheme = createTheme();
declare global {
  interface Window {
    captchaOnLoad: () => void;
    grecaptcha: any;
  }
}
export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const loadScriptByURL = (id: any, url: any, callback: () => void) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };
    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${envConstants.RECAPTCHA_KEY}`,
      () => {
        // console.log('Script recaptcha loaded!');
      }
    );
  }, []);

  const removeRecaptcha = () => {
    const script = document.getElementById("recaptcha-key");
    if (script) {
      script.remove();
    }

    const recaptchaElems = document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!username || !password) {
      setErrorText("Vui lòng điền đầy đủ thông tin");
    } else {
      await window.grecaptcha.ready(async () => {
        await window.grecaptcha
          .execute(envConstants.RECAPTCHA_KEY, { action: "submit" })
          .then(async (token: any) => {
            const result = await authService.login(username, password, token);
            if (result.success) {
              toast.success("Đăng nhập thành công");
              dispatch(setLoggedIn(true));
              dispatch(setAdmin(result.user));
              navigate("/");
              removeRecaptcha();
            } else {
              dispatch(setLoggedIn(false));
              setErrorText((result as any)?.error?.message || COMMON_ERROR_MSG);
            }
          })
          .catch((err: any) => {
            dispatch(setLoggedIn(false));
            setErrorText((err as any)?.error?.message || COMMON_ERROR_MSG);
          });
      });
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          bgcolor: "white",
          borderRadius: "8px",
          marginTop: "50px",
          padding: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
						<LockOutlinedIcon />
					</Avatar> */}
          <img
            alt="logo"
            className="mt-1"
            src={assets.images.logo}
            width={200}
          />
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            ChungVui | Admin
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              onChange={(e: any) => {
                setErrorText("");
                setUsername(removeAllSpace(e.target.value));
              }}
              label="Tên đăng nhập"
              value={username}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              onChange={(e: any) => {
                setErrorText("");
                setPassword(removeAllSpace(e.target.value));
              }}
              label="Mật khẩu"
              type={showPassword ? "text" : "password"}
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
            />
            <div
              className="cursor-pointer"
              style={{ color: "red" }}
              onClick={() => setErrorText("")}
            >
              {errorText}
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Đăng nhập
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
