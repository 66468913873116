import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Divider,
  ButtonGroup,
  TextField,
  Tooltip,
  Typography,
  Select,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  OutlinedInput,
  Avatar,
  useTheme,
  useMediaQuery,
  TableContainer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getPriceString } from "../../../helper";
import { useEffect, useState } from "react";
import ConfirmModal from "../../component/ConfirmModal";
import { Mail, Person, Phone, RecentActors } from "@mui/icons-material";

function OrderUpdateModal({
  open,
  title,
  data,
  onPass,
  onCancel,
}: {
  open: boolean;
  title: any;
  data: any;
  onPass: Function;
  onCancel: any;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [total, setTotal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);

  const onCancelConfirm = () => {
    setOpenConfirm(false);
  };
  const onPassConfirm = async (data: any) => {
    const obj = {
      ...data,
      total,
    };
    onPass(obj);
    setOpenConfirm(false);
  };

  useEffect(() => {
    if (open) {
      setTotal(data.total ?? data.sub_total);
    }
  }, [open]);

  const changeTotal = (event: any) => {
    if (+event.target.value >= 0) {
      setTotal(+event.target.value);
    } else {
      setTotal(0);
    }
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth fullScreen={fullScreen}>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <span>{title}</span>
          <IconButton
            sx={{ marginLeft: "1px" }}
            edge="start"
            color="inherit"
            onClick={onCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          px: {
            md: "24px",
            xs: "5px",
          },
        }}
      >
        <div className="text-lg font-bold pb-4 mt-1">Thông tin người dùng</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full justify-around pb-4">
          <div className="flex gap-3">
            <Avatar sx={{ width: 56, height: 56 }}>
              <Mail />
            </Avatar>
            <div>
              <div className="text-xl font-semibold text-slate-500">Email</div>
              <div className="text-base">{data.user_infor[0]?.email}</div>
            </div>
          </div>

          <div className="flex gap-3">
            <Avatar sx={{ width: 56, height: 56 }}>
              <Person />
            </Avatar>
            <div>
              <div className="text-lg font-semibold text-slate-500">
                Tên tài khoản
              </div>
              <div className="text-base">{data.user_infor[0]?.user_name}</div>
            </div>
          </div>
          {data.user_infor[0]?.fullname && (
            <div className="flex gap-3">
              <Avatar sx={{ width: 56, height: 56 }}>
                <RecentActors />
              </Avatar>
              <div>
                <div className="text-lg font-semibold text-slate-500">
                  Họ tên
                </div>
                <div className="text-base">{data.user_infor[0]?.fullname}</div>
              </div>
            </div>
          )}
          {data.user_infor[0]?.phone && (
            <div className="flex gap-3">
              <Avatar sx={{ width: 56, height: 56 }}>
                <Phone />
              </Avatar>
              <div>
                <div className="text-lg font-semibold text-slate-500">
                  Số điện thoại
                </div>
                <div className="text-base">{data.user_infor[0]?.phone}</div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="text-base ml-2 pb-2">{ user?.email && `Email: ${user.email}`}</div>
					<div className="text-base ml-2">
					{`Tên tài khoản: ${user?.user_name}`}
					</div> */}
        <Divider />

        <Divider></Divider>
        <div className="text-lg font-bold mt-3">Thông tin hóa đơn</div>
        <TableContainer sx={{}}>
          <Table
            sx={{
              "& tbody,thead > tr > *:first-child": {
                position: "sticky",
                left: 0,
                boxShadow: "1px 0 var(--TableCell-borderColor)",
                bgcolor: "InfoBackground",
              },
              "& tbody,thead > tr > *:last-child": {
                position: "sticky",
                right: 0,
                bgcolor: "InfoBackground",
              },
              "& tfoot > tr > *:first-child": {
                position: "sticky",
                left: 0,
              },
              "& tfoot > tr > *:last-child": {
                position: "sticky",
                right: 0,
              },
            }}
            aria-label="purchases"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: "8px" }}>STT</TableCell>
                <TableCell sx={{ py: "8px" }}>Gói dịch vụ</TableCell>
                <TableCell align="center" sx={{ py: "8px" }}>
                  Loại gói
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Dung lượng file (Mb)
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Số lượng khách (người)
                </TableCell>
                <TableCell align="center" sx={{ py: "8px" }}>
                  Số lượng mua
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Đơn giá (VNĐ)
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Tổng tiền (VNĐ)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.detail.map((detailRow: any, idx: number) => (
                <TableRow key={idx}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      position: "sticky",
                      left: 0,
                      boxShadow: "1px 0 var(--TableCell-borderColor)",
                      bgcolor: "InfoBackground",
                    }}
                  >
                    {idx + 1}
                  </TableCell>
                  <TableCell>{detailRow?.package_name || ""}</TableCell>
                  <TableCell align="center">
                    {detailRow.type === "base" ? "Gói chính" : "Gói phụ"}
                  </TableCell>
                  <TableCell align="right">{detailRow.file_storage}</TableCell>
                  <TableCell align="right">{detailRow.event_guest}</TableCell>
                  <TableCell align="center">{detailRow.quantity}</TableCell>
                  <TableCell align="right">
                    {getPriceString(detailRow.price).replace("VNĐ", "")}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      position: "sticky",
                      right: 0,
                      bgcolor: "InfoBackground",
                    }}
                  >
                    {getPriceString(
                      detailRow.quantity * detailRow.price
                    ).replace("VNĐ", "")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter className="bg-slate-300 rounded-lg mt-3">
              <TableRow className="bg-slate-300 rounded-lg mt-3">
                <TableCell
                  className="border rounded-l-lg"
                  style={{
                    borderRight: "1px solid #CBD5E1",
                  }}
                  sx={{
                    color: "InfoText",
                    fontWeight: 700,
                    fontSize: "1.1rem",
                    lineHeight: "1.5rem",
                    borderColor: "transparent",
                    borderBottomWidth: 0,
                  }}
                  colSpan={6}
                >{`Tổng Cộng`}</TableCell>
                <TableCell
                  className="border rounded-r-lg"
                  sx={{
                    color: "InfoText",
                    textAlign: "end",
                    fontWeight: 700,
                    fontSize: "1.1rem",
                    lineHeight: "1.5rem",
                    borderColor: "transparent",
                    borderBottomWidth: 0,
                  }}
                  colSpan={2}
                >
                  <OutlinedInput
                    fullWidth
                    type="number"
                    size="small"
                    sx={{
                      input: { textAlign: "end" },
                      bgcolor: "white",
                      "input[type=number]::-webkit-inner-spin-button": {
                        appearance: "none",
                      },
                      "input[type=number]::-webkit-outer-spin-button ": {
                        appearance: "none",
                      },
                    }}
                    onChange={changeTotal}
                    value={total}
                  ></OutlinedInput>
                </TableCell>
              </TableRow>
            </TableFooter>
            <TableFooter>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    borderColor: "#BFDBFE",
                    borderBottomWidth: 0,
                  }}
                  colSpan={6}
                >{`Tạm Tính `}</TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    textAlign: "end",
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    borderColor: "#BFDBFE",
                    borderBottomWidth: 0,
                  }}
                  colSpan={2}
                >{`${getPriceString(data.sub_total)}`}</TableCell>
              </TableRow>
            </TableFooter>
            <TableFooter className="leading-tight p-0">
              <TableCell
                colSpan={8}
                className="leading-tight p-0 border-0"
                sx={{ borderBottomWidth: 0, p: 0 }}
              >
                <Divider sx={{ mb: 1 }} className="bg-blue-200" />
              </TableCell>
            </TableFooter>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider />
      <DialogActions className="flex ml-auto">
        <Button onClick={() => onCancel(data)} variant="outlined">
          Hủy bỏ
        </Button>
        <Button onClick={() => setOpenConfirm(true)} variant="contained">
          Xác nhận
        </Button>
      </DialogActions>
      <ConfirmModal
        open={openConfirm}
        data={{ ...data, total }}
        onCancel={onCancelConfirm}
        onPass={onPassConfirm}
        title={"Xác nhận cập nhập tổng tiền"}
        key={data._id}
        content={
          <Typography variant="body1">
            Bạn có chắc muốn cập nhật thành tiền của{" "}
            <b>
              {data?.order_alias_string +
                data?.order_alias_number?.toString().padStart(5, "0")}
            </b>{" "}
            không ?
          </Typography>
        }
      />
    </Dialog>
  );
}

export default OrderUpdateModal;
