import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  FormControl,
  TextareaAutosize,
  InputLabel,
  FormHelperText,
  Typography,
  Chip,
  Avatar,
  useTheme,
  useMediaQuery,
  TableContainer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { toast } from "react-toastify";
import { Package } from "../../../interface/package";
import { LIMIT_RESOURCE } from "../../../configs/enum";
import { getPriceString, getResourceUnit } from "../../../helper";
import packageService from "../../../services/packages.service";
import ConfirmModal from "../../component/ConfirmModal";
import moment from "moment";
import { Edit, Mail, Person, Phone, RecentActors } from "@mui/icons-material";
interface Props {
  open: boolean;
  onClose: any;
  data: any;
}

const OrderInforDialog = ({
  open,
  onClose,
  data = {
    order_alias_string: "",
    order_alias_number: 0,
    user_infor: [],
    detail: [],
  },
}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog open={open} maxWidth="lg" fullWidth fullScreen={fullScreen}>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <span>
            Chi tiết đơn hàng{" "}
            {data?.order_alias_string +
              data?.order_alias_number?.toString()?.padStart(5, "0")}
          </span>
          <IconButton
            sx={{ marginLeft: "1px" }}
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
      </DialogTitle>
      <DialogContent
        sx={{
          px: {
            md: "24px",
            xs: "5px",
          },
        }}
      >
        <div className="grid grid-cols-2 md:grid-cols-4 w-full py-2 gap-2 md:gap-4 rounded-lg bg-slate-200 justify-between">
          <div className="gap-10 mb-2 pl-3">
            <div className="text-lg font-bold mb-3"> Mã hóa đơn</div>
            <div className="text-base">
              {" "}
              {data?.order_alias_string +
                data?.order_alias_number?.toString()?.padStart(5, "0")}
            </div>
          </div>
          <div className="gap-3 mb-2 pl-3">
            <div className="text-lg font-bold mb-3"> Trạng thái đơn hàng</div>
            <div className="text-base mx-auto">
              {data.status === "pending" ? (
                <Chip color="error" label={"Chưa xử lý"} />
              ) : data.status === "completed" ? (
                <Chip color="success" label={"Hoàn thành"} />
              ) : data.status === "paid" ? (
                <Chip color="warning" label={"Đã thanh toán"} />
              ) : (
                <Chip color="default" label={"Hủy đơn"} />
              )}
            </div>
          </div>
          <div className="gap-3 mb-2 pl-3">
            <div className="text-lg font-bold mb-3"> Ngày tạo</div>
            <div className="text-base">
              {moment(new Date(data.createdAt)).format("DD-MM-YYYY HH:mm")}{" "}
            </div>
          </div>
          <div className="gap-3 mb-2 pl-3">
            <div className="text-lg font-bold mb-3"> Ngày hoàn thành</div>
            <div className="text-base">
              {data.updatedAt &&
              (data.status === "completed" || data.status === "canceled")
                ? moment(new Date(data.updatedAt)).format("DD-MM-YYYY HH:mm")
                : "--/--/---"}
            </div>
          </div>
        </div>
        <div className="text-lg font-bold pb-4 mt-4">Thông tin người dùng</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pl-2 w-full justify-around pb-4">
          <div className="flex gap-3">
            <Avatar sx={{ width: 56, height: 56 }}>
              <Mail />
            </Avatar>
            <div>
              <div className="text-xl font-semibold text-slate-500">Email</div>
              <div className="text-base">{data.user_infor[0]?.email}</div>
            </div>
          </div>

          <div className="flex gap-3">
            <Avatar sx={{ width: 56, height: 56 }}>
              <Person />
            </Avatar>
            <div>
              <div className="text-lg font-semibold text-slate-500">
                Tên tài khoản
              </div>
              <div className="text-base">{data.user_infor[0]?.user_name}</div>
            </div>
          </div>
          {data.user_infor[0]?.fullname && (
            <div className="flex gap-3">
              <Avatar sx={{ width: 56, height: 56 }}>
                <RecentActors />
              </Avatar>
              <div>
                <div className="text-lg font-semibold text-slate-500">
                  Họ tên
                </div>
                <div className="text-base">{data.user_infor[0]?.fullname}</div>
              </div>
            </div>
          )}
          {data.user_infor[0]?.phone && (
            <div className="flex gap-3">
              <Avatar sx={{ width: 56, height: 56 }}>
                <Phone />
              </Avatar>
              <div>
                <div className="text-lg font-semibold text-slate-500">
                  Số điện thoại
                </div>
                <div className="text-base">{data.user_infor[0]?.phone}</div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="text-base ml-2 pb-2">{ user?.email && `Email: ${user.email}`}</div>
					<div className="text-base ml-2">
					{`Tên tài khoản: ${user?.user_name}`}
					</div> */}
        <Divider />
        <div className="text-lg font-bold mt-3">Thông tin hóa đơn</div>
        <TableContainer sx={{}}>
          <Table
            sx={{
              "& tbody,thead > tr > *:first-child": {
                position: "sticky",
                left: 0,
                boxShadow: "1px 0 var(--TableCell-borderColor)",
                bgcolor: "InfoBackground",
              },
              "& tbody,thead > tr > *:last-child": {
                position: "sticky",
                right: 0,
                bgcolor: "InfoBackground",
              },
              "& tfoot > tr > *:first-child": {
                position: "sticky",
                left: 0,
              },
              "& tfoot > tr > *:last-child": {
                position: "sticky",
                right: 0,
              },
            }}
            aria-label="purchases"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: "8px" }}>STT</TableCell>
                <TableCell sx={{ py: "8px" }}>Gói dịch vụ</TableCell>
                <TableCell align="center" sx={{ py: "8px" }}>
                  Loại gói
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Dung lượng file (Mb)
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Số lượng khách (người)
                </TableCell>
                <TableCell align="center" sx={{ py: "8px" }}>
                  Số lượng mua
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Đơn giá (VNĐ)
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Tổng tiền (VNĐ)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.detail.map((detailRow: any, idx: number) => (
                <TableRow key={idx}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      position: "sticky",
                      left: 0,
                      boxShadow: "1px 0 var(--TableCell-borderColor)",
                      bgcolor: "InfoBackground",
                    }}
                  >
                    {idx + 1}
                  </TableCell>
                  <TableCell>{detailRow?.package_name || ""}</TableCell>
                  <TableCell align="center">
                    {detailRow.type === "base" ? "Gói chính" : "Gói phụ"}
                  </TableCell>
                  <TableCell align="right">{detailRow.file_storage}</TableCell>
                  <TableCell align="right">{detailRow.event_guest}</TableCell>
                  <TableCell align="center">{detailRow.quantity}</TableCell>
                  <TableCell align="right">
                    {getPriceString(detailRow.price).replace("VNĐ", "")}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      position: "sticky",
                      right: 0,
                      bgcolor: "InfoBackground",
                    }}
                  >
                    {getPriceString(
                      detailRow.quantity * detailRow.price
                    ).replace("VNĐ", "")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter className="bg-slate-300 rounded-lg mt-3">
              <TableRow className="bg-slate-300 rounded-lg mt-3">
                <TableCell
                  className="border rounded-l-lg"
                  style={{
                    borderRight: "1px solid #CBD5E1",
                  }}
                  sx={{
                    color: "InfoText",
                    fontWeight: 700,
                    fontSize: "1.1rem",
                    lineHeight: "1.5rem",
                    borderColor: "transparent",
                    borderBottomWidth: 0,
                  }}
                  colSpan={6}
                >{`Tổng Cộng`}</TableCell>
                <TableCell
                  className="border rounded-r-lg"
                  sx={{
                    color: "InfoText",
                    textAlign: "end",
                    fontWeight: 700,
                    fontSize: "1.1rem",
                    lineHeight: "1.5rem",
                    borderColor: "transparent",
                    borderBottomWidth: 0,
                  }}
                  colSpan={2}
                >{`${getPriceString(data.total)}`}</TableCell>
              </TableRow>
            </TableFooter>
            <TableFooter>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    borderColor: "#BFDBFE",
                    borderBottomWidth: 0,
                  }}
                  colSpan={6}
                >{`Tạm Tính `}</TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    textAlign: "end",
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    borderColor: "#BFDBFE",
                    borderBottomWidth: 0,
                  }}
                  colSpan={2}
                >{`${getPriceString(data.sub_total)}`}</TableCell>
              </TableRow>
            </TableFooter>
            <TableFooter className="leading-tight p-0">
              <TableCell
                colSpan={8}
                className="leading-tight p-0 border-0"
                sx={{ borderBottomWidth: 0, p: 0 }}
              >
                <Divider sx={{ mb: 1 }} className="bg-blue-200" />
              </TableCell>
            </TableFooter>
          </Table>
        </TableContainer>
        {data.reason && (
          <div className="text-lg font-bold mt-3">Lý do hủy đơn</div>
        )}
        <Typography variant="body1" sx={{ ml: 2 }} gutterBottom component="div">
          {data.reason}
        </Typography>
        {data?.receiver?.full_name && (
          <>
            <div className="mt-3 mb-1">
              <div className="text-lg font-bold mt-3">Thông tin thanh toán</div>
              <div className="flex gap-3 ml-3">
                <Typography
                  className="font-semibold"
                  variant="body1"
                  gutterBottom
                  component="div"
                >
                  Người xác nhận:
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                  {data?.receiver?.full_name}
                </Typography>
              </div>
              <div className="flex gap-3 ml-3">
                <Typography
                  className="font-semibold"
                  variant="body1"
                  gutterBottom
                  component="div"
                >
                  Hình thức thành toán:
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                  {data?.payment_method === "cash" && "Tiền mặt"}
                  {data?.payment_method === "bank" && "Chuyển khoản"}
                </Typography>
              </div>
              {data?.payment_method === "bank" && (
                <div className="flex gap-3 ml-3">
                  <Typography
                    className="font-semibold"
                    variant="body1"
                    gutterBottom
                    component="div"
                  >
                    {data?.receiver?.bank_name && "Tài khoản thụ hưởng:"}
                  </Typography>
                  <Typography variant="body1" gutterBottom component="div">
                    {data?.receiver?.bank_name} - {data?.receiver?.bank_number}{" "}
                    ({data?.receiver?.bank_account})
                  </Typography>
                </div>
              )}
              {data.note && (
                <div className="flex gap-3 ml-3">
                  <Typography
                    className="font-semibold"
                    variant="body1"
                    gutterBottom
                    component="div"
                  >
                    Ghi chú:
                  </Typography>
                  <Typography variant="body1" gutterBottom component="div">
                    <i>{data.note}</i>
                  </Typography>
                </div>
              )}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Quay lại
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderInforDialog;
