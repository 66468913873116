import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  FormControl,
  TextareaAutosize,
  InputLabel,
  FormHelperText,
  Box,
  Stepper,
  Step,
  StepLabel,
  Avatar,
  StepIconProps,
  styled,
  StepContent,
  Typography,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { toast } from "react-toastify";
import { Package } from "../../../interface/package";
import { LIMIT_RESOURCE } from "../../../configs/enum";
import { getPriceString, getResourceUnit } from "../../../helper";
import packageService from "../../../services/packages.service";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Order } from "../../../interface/order";
import moment from "moment";

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#3498db",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "#3498db",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "#3498db",
  }),
}));

function StatusHistory({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: Function;
  data: Order;
}) {
  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {data?.order_history?.length - Number(props.icon) + 1}
      </ColorlibStepIconRoot>
    );
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <span>
            Lịch sử trạng thái đơn hàng{" "}
            {data.order_alias_string +
              data.order_alias_number?.toString().padStart(5, "0")}
          </span>
          <IconButton
            sx={{ marginLeft: "1px" }}
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
      </DialogTitle>

      <DialogContent className={"resource-info px-[24px]"}>
        <Box>
          <Stepper
            activeStep={data?.order_history?.length || 0}
            orientation="vertical"
          >
            {data?.order_history?.length > 0 &&
              data?.order_history
                .sort((a, b) => {
                  const date1 = new Date(a.time);
                  const date2 = new Date(b.time);
                  return date2.getTime() - date1.getTime();
                })
                .map((x, index, arr) => (
                  <Step>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {x.user_type === "admin" &&
                        "Người quản trị " +
                          data.admins_infor?.find((o) => o._id === x.user_id)
                            ?.user_name}
                      {x.user_type === "user" &&
                        "Khách hàng " +
                          data.user_infor?.find((o) => o._id === x.user_id)
                            ?.user_name}
                      {" đã"}
                      {x.method === "create" && " tạo hóa đơn và"}
                      {x.content?.status && " chuyển trạng thái sang   "}
                      {!x?.content?.status && x?.content?.total && (
                        <>
                          {" "}
                          cập nhật thành tiền từ{" "}
                          <b>
                            {getPriceString(arr[index + 1]?.content.total)} VNĐ
                          </b>{" "}
                          thành <b>{getPriceString(x?.content?.total)} VNĐ</b>
                        </>
                      )}
                      {x.method === "create" && (
                        <Chip label="Chưa xử lý"></Chip>
                      )}
                      {x.method === "paid" && x.user_type === "user" && (
                        <Chip color="warning" label="Đã thanh toán"></Chip>
                      )}
                      {x.method === "completed" && (
                        <Chip color="success" label="Đã hoàn thành"></Chip>
                      )}
                      {x.method === "canceled" && (
                        <Chip color="error" label="Hủy đơn"></Chip>
                      )}
                      {"   vào " + moment(x.time).format("DD/MM/Y HH:mm")}
                    </StepLabel>
                    <StepContent>
                      <Typography>{"Test"}</Typography>
                    </StepContent>
                  </Step>
                ))}
            {!data?.order_history?.length && (
              <Typography
                className="m-3 text-center text-slate-500"
                variant="body1"
              >
                Chưa có thông tin lịch sử trạng thái nào !
              </Typography>
            )}
          </Stepper>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default StatusHistory;
