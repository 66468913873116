import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  TextareaAutosize,
  Avatar,
  TableContainer,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { toast } from "react-toastify";
import { Package } from "../../../interface/package";
import { LIMIT_RESOURCE } from "../../../configs/enum";
import { getPriceString, getResourceUnit } from "../../../helper";
import packageService from "../../../services/packages.service";
import orderService from "../../../services/order.service";
import ConfirmModal from "../../component/ConfirmModal";
import { Mail, Person, Phone, RecentActors } from "@mui/icons-material";
interface Props {
  open: boolean;
  onClose: any;
  completeHandler: Function;
  data: any;
}

const defaultReceiver = {
  full_name: "",
  bank_name: "",
  bank_number: "",
  bank_account: "",
};

// const defaultBankInffor = [
// 	{
// 		key: 'account_1',
// 		bank_name: 'ABCD Bank',
// 		bank_number: '123456789',
// 		bank_account: 'Nguyen Van A',
// 	},
// 	{
// 		key: 'account_2',
// 		bank_name: 'ABCD Bank',
// 		bank_number: '123456789',
// 		bank_account: 'Nguyen Van B',
// 	},
// 	{
// 		key: 'account_3',
// 		bank_name: 'ABCD Bank',
// 		bank_number: '123456789',
// 		bank_account: 'Nguyen Van C',
// 	}
// ]

const CompleteDialog = ({
  open,
  onClose,
  completeHandler,
  data = {
    order_alias_string: "",
    order_alias_number: 0,
    user_infor: [],
    detail: [],
  },
}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setPaymentMethod("bank");
    setReceiverName("");
    setValidateReceiverName({
      invalid: false,
      message: "",
    });
    setReceiverNameBank("account_1");
    onClose();
  };

  const [paymentMethod, setPaymentMethod] = useState("bank");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [defaultBankInffor, setDefaultBankInffor] = useState<any[]>([]);
  const [receiverName, setReceiverName] = useState("");
  const [validateReceiverName, setValidateReceiverName] = useState({
    invalid: false,
    message: "",
  });
  const [note, setNote] = useState("");
  const [receiverBank, setReceiverNameBank] = useState("account_1");

  const handleSubmit = async () => {
    completeHandler({
      ...data,
      note,
      ...(data.status === "paid" || data.status === "pending"
        ? {
            receiver: {
              ...((data.status === "paid" || data.status === "pending") &&
              paymentMethod === "cash"
                ? {
                    full_name: receiverName,
                  }
                : {}),
              ...((data.status === "paid" || data.status === "pending") &&
              paymentMethod === "bank"
                ? {
                    full_name: receiverName,
                    ...defaultBankInffor.find((x) => x.key === receiverBank),
                  }
                : {}),
            },
          }
        : {}),
      ...(data.status === "paid" || data.status === "pending"
        ? { payment_method: paymentMethod }
        : {}),
    });
    // console.log({
    // 	...data,
    // 	...(data.status === 'paid'
    // 		? {
    // 			payment_method: paymentMethod,
    // 			full_name: receiverName,
    // 			...(defaultBankInffor.find(x=>x.key === receiverBank)),
    // 			receiver: {
    // 				...(data.status === 'paid' && paymentMethod === 'cash' ? {
    // 					full_name: receiverName,
    // 				}:{}),
    // 				...(data.status === 'paid' && paymentMethod === 'bank' ? {
    // 					full_name: receiverName,
    // 					...(defaultBankInffor.find(x=>x.key === receiverBank)),
    // 				}:{})
    // 			},
    // 		}: {}
    // 	),
    // 	// ...(data.status === 'paid' ? {payment_method: paymentMethod} : {}),
    // })
  };

  const onCancelConfirm = () => {
    setOpenConfirm(false);
  };
  const onPassConfirm = async () => {
    await handleSubmit();
    setOpenConfirm(false);
  };

  const validateReceiverHandler = () => {
    if (receiverName === "") {
      setValidateReceiverName({
        invalid: true,
        message: "Vui lòng nhập tên người xác nhận",
      });
    } else {
      setValidateReceiverName({
        invalid: false,
        message: "",
      });
    }
  };

  const onChangePayment = (event: any) => {
    setPaymentMethod(event.target.value);
  };
  const onChangeBank = (event: any) => {
    setReceiverNameBank(event.target.value);
  };

  const getBankInfor = async () => {
    const result = await orderService.getBankList();
    if (result?.success) {
      const { data } = result;
      setDefaultBankInffor(
        data.map((x: any, i: number) => {
          x.key = "account_" + (i + 1);
          x.bank_name = x.account_address;
          x.bank_number = x.account_number;
          x.bank_account = x.account_name;
          return x;
        })
      );
    }
  };

  useEffect(() => {
    if (open) {
      getBankInfor();
      setPaymentMethod("bank");
      setReceiverName("");
      setReceiverNameBank("account_1");
      setNote("");
    }
    // const enable = !!(
    // 	eventGuestInput && fileStorageInput && priceInput && nameInput)
    // setEnableButton(enable)
  }, [open]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth fullScreen={fullScreen}>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <span>
            Xác nhận Hoàn thành đơn hàng{" "}
            {data?.order_alias_string +
              data?.order_alias_number?.toString().padStart(5, "0")}
          </span>
          <IconButton
            sx={{ marginLeft: "1px" }}
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
      </DialogTitle>
      <DialogContent
        className={"resource-info"}
        sx={{
          px: {
            md: "24px",
            xs: "5px",
          },
        }}
      >
        <div className="text-lg font-bold pb-4 mt-1">Thông tin người dùng</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full justify-around pb-4">
          <div className="flex gap-3">
            <Avatar sx={{ width: 56, height: 56 }}>
              <Mail />
            </Avatar>
            <div>
              <div className="text-xl font-semibold text-slate-500">Email</div>
              <div className="text-base">{data.user_infor[0]?.email}</div>
            </div>
          </div>

          <div className="flex gap-3">
            <Avatar sx={{ width: 56, height: 56 }}>
              <Person />
            </Avatar>
            <div>
              <div className="text-lg font-semibold text-slate-500">
                Tên tài khoản
              </div>
              <div className="text-base">{data.user_infor[0]?.user_name}</div>
            </div>
          </div>
          {data.user_infor[0]?.fullname && (
            <div className="flex gap-3">
              <Avatar sx={{ width: 56, height: 56 }}>
                <RecentActors />
              </Avatar>
              <div>
                <div className="text-lg font-semibold text-slate-500">
                  Họ tên
                </div>
                <div className="text-base">{data.user_infor[0]?.fullname}</div>
              </div>
            </div>
          )}
          {data.user_infor[0]?.phone && (
            <div className="flex gap-3">
              <Avatar sx={{ width: 56, height: 56 }}>
                <Phone />
              </Avatar>
              <div>
                <div className="text-lg font-semibold text-slate-500">
                  Số điện thoại
                </div>
                <div className="text-base">{data.user_infor[0]?.phone}</div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="text-base ml-2 pb-2">{ user?.email && `Email: ${user.email}`}</div>
					<div className="text-base ml-2">
					{`Tên tài khoản: ${user?.user_name}`}
					</div> */}
        <Divider />
        <div className="text-lg font-bold mt-3">Thông tin hóa đơn</div>
        <TableContainer sx={{}}>
          <Table
            sx={{
              "& tbody,thead > tr > *:first-child": {
                position: "sticky",
                left: 0,
                boxShadow: "1px 0 var(--TableCell-borderColor)",
                bgcolor: "InfoBackground",
              },
              "& tbody,thead > tr > *:last-child": {
                position: "sticky",
                right: 0,
                bgcolor: "InfoBackground",
              },
              "& tfoot > tr > *:first-child": {
                position: "sticky",
                left: 0,
              },
              "& tfoot > tr > *:last-child": {
                position: "sticky",
                right: 0,
              },
            }}
            aria-label="purchases"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: "8px" }}>STT</TableCell>
                <TableCell sx={{ py: "8px" }}>Gói dịch vụ</TableCell>
                <TableCell align="center" sx={{ py: "8px" }}>
                  Loại gói
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Dung lượng file (Mb)
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Số lượng khách (người)
                </TableCell>
                <TableCell align="center" sx={{ py: "8px" }}>
                  Số lượng mua
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Đơn giá (VNĐ)
                </TableCell>
                <TableCell align="right" sx={{ py: "8px" }}>
                  Tổng tiền (VNĐ)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.detail.map((detailRow: any, idx: number) => (
                <TableRow key={idx}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      position: "sticky",
                      left: 0,
                      boxShadow: "1px 0 var(--TableCell-borderColor)",
                      bgcolor: "InfoBackground",
                    }}
                  >
                    {idx + 1}
                  </TableCell>
                  <TableCell>{detailRow?.package_name || ""}</TableCell>
                  <TableCell align="center">
                    {detailRow.type === "base" ? "Gói chính" : "Gói phụ"}
                  </TableCell>
                  <TableCell align="right">{detailRow.file_storage}</TableCell>
                  <TableCell align="right">{detailRow.event_guest}</TableCell>
                  <TableCell align="center">{detailRow.quantity}</TableCell>
                  <TableCell align="right">
                    {getPriceString(detailRow.price).replace("VNĐ", "")}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      position: "sticky",
                      right: 0,
                      bgcolor: "InfoBackground",
                    }}
                  >
                    {getPriceString(
                      detailRow.quantity * detailRow.price
                    ).replace("VNĐ", "")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter className="bg-slate-300 rounded-lg mt-3">
              <TableRow className="bg-slate-300 rounded-lg mt-3">
                <TableCell
                  className="border rounded-l-lg"
                  style={{
                    borderRight: "1px solid #CBD5E1",
                  }}
                  sx={{
                    color: "InfoText",
                    fontWeight: 700,
                    fontSize: "1.1rem",
                    lineHeight: "1.5rem",
                    borderColor: "transparent",
                    borderBottomWidth: 0,
                  }}
                  colSpan={6}
                >{`Tổng Cộng`}</TableCell>
                <TableCell
                  className="border rounded-r-lg"
                  sx={{
                    color: "InfoText",
                    textAlign: "end",
                    fontWeight: 700,
                    fontSize: "1.1rem",
                    lineHeight: "1.5rem",
                    borderColor: "transparent",
                    borderBottomWidth: 0,
                  }}
                  colSpan={2}
                >{`${getPriceString(data.total)}`}</TableCell>
              </TableRow>
            </TableFooter>
            <TableFooter>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    borderColor: "#BFDBFE",
                    borderBottomWidth: 0,
                  }}
                  colSpan={6}
                >{`Tạm Tính `}</TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    textAlign: "end",
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    borderColor: "#BFDBFE",
                    borderBottomWidth: 0,
                  }}
                  colSpan={2}
                >{`${getPriceString(data.sub_total)}`}</TableCell>
              </TableRow>
            </TableFooter>
            <TableFooter className="leading-tight p-0">
              <TableCell
                colSpan={8}
                className="leading-tight p-0 border-0"
                sx={{ borderBottomWidth: 0, p: 0 }}
              >
                <Divider sx={{ mb: 1 }} className="bg-blue-200" />
              </TableCell>
            </TableFooter>
          </Table>
        </TableContainer>
        <Divider sx={{ my: 2 }} />
        {(data.status === "paid" || data.status === "pending") && (
          <Box>
            <div className="text-lg font-bold mt-3">Thông tin thanh toán</div>
            <div className="grid grid-cols-4 gap-3 my-3">
              <FormControl required className="col-span-2 md:col-span-1">
                <InputLabel>Loại hình thanh toán</InputLabel>
                <Select
                  value={paymentMethod}
                  onChange={onChangePayment}
                  label="Loại hình thanh toán"
                  defaultValue={"bank"}
                >
                  <MenuItem value="cash">Tiền mặt</MenuItem>
                  <MenuItem value="bank">Chuyển khoản</MenuItem>
                </Select>
              </FormControl>
              <FormControl required className="col-span-2 md:col-span-1">
                <TextField
                  required
                  onBlur={validateReceiverHandler}
                  error={validateReceiverName.invalid}
                  helperText={validateReceiverName.message}
                  className={receiverBank ? "col-span-2" : ""}
                  label="Người xác nhận"
                  value={receiverName}
                  onChange={(event) => {
                    if (event.target.value !== "") {
                      setValidateReceiverName({
                        invalid: false,
                        message: "",
                      });
                    }
                    setReceiverName(event.target.value as any);
                  }}
                />
              </FormControl>
              {paymentMethod === "bank" && receiverBank && (
                <FormControl className="col-span-4 md:col-span-2" required>
                  <InputLabel>Tài khoản thụ hưởng</InputLabel>
                  <Select
                    label="Tài khoản thụ hưởng"
                    // defaultValue='account_1'
                    onChange={onChangeBank}
                    value={receiverBank}
                  >
                    {defaultBankInffor.map((x) => (
                      <MenuItem value={x.key}>
                        {x.bank_name}-{x.bank_account}({x.bank_number})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl fullWidth className="col-span-4">
                <TextField
                  multiline
                  minRows={3}
                  value={note}
                  onChange={(event) => {
                    setNote(event.target.value);
                  }}
                  label="Ghi chú"
                ></TextField>
              </FormControl>
            </div>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Quay lại
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (
              (data.status === "paid" || data.status === "pending") &&
              receiverName === ""
            ) {
              setValidateReceiverName({
                invalid: true,
                message: "Vui lòng nhập tên người xác nhận",
              });
              return;
            }
            setOpenConfirm(true);
          }}
        >
          Xác nhận
        </Button>
      </DialogActions>
      <ConfirmModal
        open={openConfirm}
        data={null}
        onCancel={onCancelConfirm}
        onPass={onPassConfirm}
        title={"Xác nhận hoàn thành"}
        key={data._id}
        content={
          <Typography variant="body1">
            Bạn có chắc muốn hoàn thành đơn hàng{" "}
            <b>
              {data?.order_alias_string +
                data?.order_alias_number?.toString().padStart(5, "0")}
            </b>{" "}
            không ?
          </Typography>
        }
      />
    </Dialog>
  );
};

export default CompleteDialog;
